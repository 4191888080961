"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMPONENT_TYPE = exports.ITEM_TYPE = exports.ACTION_TYPE = exports.PANEL_INSERTION_TYPE = exports.CONNECTED_END_FACE = exports.CONNECTED_FACE = exports.CONNECTION_TYPE = void 0;
// eslint-disable-next-line no-shadow
var CONNECTION_TYPE;
(function (CONNECTION_TYPE) {
    CONNECTION_TYPE["PERPENDICULAR"] = "perpendicular";
    CONNECTION_TYPE["END_TO_END"] = "end-to-end";
    CONNECTION_TYPE["PARALLEL"] = "parallel";
    CONNECTION_TYPE["ANGLED"] = "angled";
    CONNECTION_TYPE["COMPONENT"] = "component";
    CONNECTION_TYPE["PANEL_TO_EXTRUSION_SIDE"] = "panel-to-extrusion-side";
    CONNECTION_TYPE["PANEL_TO_EXTRUSION_SLOT"] = "panel-to-extrusion-slot";
})(CONNECTION_TYPE = exports.CONNECTION_TYPE || (exports.CONNECTION_TYPE = {}));
// eslint-disable-next-line no-shadow
var CONNECTED_FACE;
(function (CONNECTED_FACE) {
    CONNECTED_FACE["SIDE"] = "side";
    CONNECTED_FACE["END"] = "end";
    CONNECTED_FACE["FASTENER"] = "fastener";
})(CONNECTED_FACE = exports.CONNECTED_FACE || (exports.CONNECTED_FACE = {}));
// eslint-disable-next-line no-shadow
var CONNECTED_END_FACE;
(function (CONNECTED_END_FACE) {
    CONNECTED_END_FACE["A"] = "A";
    CONNECTED_END_FACE["B"] = "B";
})(CONNECTED_END_FACE = exports.CONNECTED_END_FACE || (exports.CONNECTED_END_FACE = {}));
// eslint-disable-next-line no-shadow
var PANEL_INSERTION_TYPE;
(function (PANEL_INSERTION_TYPE) {
    PANEL_INSERTION_TYPE["DEFAULT"] = "default";
    PANEL_INSERTION_TYPE["CONTOUR"] = "contour";
    PANEL_INSERTION_TYPE["SIDE_OR_END"] = "side_or_end";
})(PANEL_INSERTION_TYPE = exports.PANEL_INSERTION_TYPE || (exports.PANEL_INSERTION_TYPE = {}));
// eslint-disable-next-line no-shadow
var ACTION_TYPE;
(function (ACTION_TYPE) {
    ACTION_TYPE["ADD"] = "add";
    ACTION_TYPE["MOVE"] = "move";
    ACTION_TYPE["REMOVE"] = "remove";
    ACTION_TYPE["ROTATE"] = "rotate";
    ACTION_TYPE["SCALE1D"] = "scale1D";
    ACTION_TYPE["SCALE2D"] = "scale2D";
    ACTION_TYPE["UPDATE"] = "update";
    ACTION_TYPE["COPY"] = "copy";
})(ACTION_TYPE = exports.ACTION_TYPE || (exports.ACTION_TYPE = {}));
// eslint-disable-next-line no-shadow
exports.ITEM_TYPE = {
    EXTRUSION: 'Extrusion',
    COMPONENT: 'Component',
    PANEL: 'Panel',
    FASTENER: 'Fastener',
    BOM_BALLON: 'BomBallon',
    STEM_CASTER: 'Stem caster',
    FOOT: 'Foot',
};
// eslint-disable-next-line no-shadow
var COMPONENT_TYPE;
(function (COMPONENT_TYPE) {
    COMPONENT_TYPE["FASTENER"] = "Fastener";
    COMPONENT_TYPE["DOOR_SLIDE_TRACK"] = "Door slide track";
    COMPONENT_TYPE["END_CAP"] = "End cap";
    COMPONENT_TYPE["TSLOT_COVER"] = "T-slot cover";
    COMPONENT_TYPE["PANEL"] = "Panel";
    COMPONENT_TYPE["PANEL_GASKET"] = "Panel gasket";
    COMPONENT_TYPE["PANEL_MOUNT_BLOCK"] = "Panel mount block";
    COMPONENT_TYPE["PANEL_RETAINER"] = "Panel retainer";
    COMPONENT_TYPE["MESH_RETAINER_ASSEMBLY"] = "Mesh retainer assembly";
    COMPONENT_TYPE["DELUXE_DOOR_HANDLE"] = "Deluxe door handle";
    COMPONENT_TYPE["FURNITURE_STYLE_DOOR_HANDLE"] = "Furniture style door handle";
    COMPONENT_TYPE["FOOT"] = "Foot";
    COMPONENT_TYPE["STEM_CASTER"] = "Stem caster";
    COMPONENT_TYPE["HANDLE"] = "Handle";
    COMPONENT_TYPE["HINGE"] = "Hinge";
    COMPONENT_TYPE["LATCH"] = "Latch";
    COMPONENT_TYPE["BASE_PLATE"] = "Base plate";
})(COMPONENT_TYPE = exports.COMPONENT_TYPE || (exports.COMPONENT_TYPE = {}));
