"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setExportQueue = exports.requestExportQueue = exports.requestSocketExportStepProgress = exports.requestSocketExportStepSuccess = exports.requestSocketExportStep = exports.requestSocketLockProject = exports.setViewMode = exports.toggleProjectDetailsPopover = exports.sendProjectScreenshot = exports.setDefaultProjectFastener = exports.setProjectLoading = exports.closeProject = exports.updateProject = exports.deleteProject = exports.editProjectSuccess = exports.editProjectWithoutBom = exports.editProject = exports.duplicateProjectSuccess = exports.duplicateProject = exports.createProjectSuccess = exports.createProject = exports.getProjectSuccess = exports.getProject = exports.getCurrentProjectFailed = exports.getCurrentProjectSuccess = exports.getCurrentProject = void 0;
const typesafe_actions_1 = require("typesafe-actions");
const consts_1 = require("./consts");
exports.getCurrentProject = (0, typesafe_actions_1.createAction)(consts_1.GET_CURRENT_PROJECT)();
exports.getCurrentProjectSuccess = (0, typesafe_actions_1.createAction)(consts_1.GET_CURRENT_PROJECT_SUCCESS)();
exports.getCurrentProjectFailed = (0, typesafe_actions_1.createAction)(consts_1.GET_CURRENT_PROJECT_FAILED)();
exports.getProject = (0, typesafe_actions_1.createAction)(consts_1.GET_PROJECT)();
exports.getProjectSuccess = (0, typesafe_actions_1.createAction)(consts_1.GET_PROJECT_SUCCESS)();
exports.createProject = (0, typesafe_actions_1.createAction)(consts_1.CREATE_PROJECT)();
exports.createProjectSuccess = (0, typesafe_actions_1.createAction)(consts_1.CREATE_PROJECT_SUCCESS)();
exports.duplicateProject = (0, typesafe_actions_1.createAction)(consts_1.DUPLICATE_PROJECT)();
exports.duplicateProjectSuccess = (0, typesafe_actions_1.createAction)(consts_1.DUPLICATE_PROJECT_SUCCESS)();
exports.editProject = (0, typesafe_actions_1.createAction)(consts_1.EDIT_PROJECT)();
exports.editProjectWithoutBom = (0, typesafe_actions_1.createAction)(consts_1.EDIT_PROJECT_WITHOUT_BOM)();
exports.editProjectSuccess = (0, typesafe_actions_1.createAction)(consts_1.EDIT_PROJECT_SUCCESS)();
exports.deleteProject = (0, typesafe_actions_1.createAction)(consts_1.DELETE_PROJECT)();
exports.updateProject = (0, typesafe_actions_1.createAction)(consts_1.UPDATE_PROJECT)();
exports.closeProject = (0, typesafe_actions_1.createAction)(consts_1.CLOSE_PROJECT)();
exports.setProjectLoading = (0, typesafe_actions_1.createAction)(consts_1.SET_PROJECT_LOADING)();
exports.setDefaultProjectFastener = (0, typesafe_actions_1.createAction)(consts_1.SET_DEFAULT_PROJECT_FASTENERS)();
exports.sendProjectScreenshot = (0, typesafe_actions_1.createAction)(consts_1.SEND_PROJECT_SCREENSHOT)();
exports.toggleProjectDetailsPopover = (0, typesafe_actions_1.createAction)(consts_1.TOGGLE_PROJECT_DETAILS_POPOVER)();
exports.setViewMode = (0, typesafe_actions_1.createAction)(consts_1.SET_VIEW_MODE)();
exports.requestSocketLockProject = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_LOCK_PROJECT)();
exports.requestSocketExportStep = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_EXPORT_STEP)();
exports.requestSocketExportStepSuccess = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_EXPORT_STEP_SUCCESS)();
exports.requestSocketExportStepProgress = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_SOCKET_EXPORT_STEP_PROGRESS)();
exports.requestExportQueue = (0, typesafe_actions_1.createAction)(consts_1.REQUEST_EXPORT_QUEUE)();
exports.setExportQueue = (0, typesafe_actions_1.createAction)(consts_1.SET_EXPORT_QUEUE)();
